/* 
  Error handler untuk di gunakan di composition API
*/
export default function (error, title, toast) {
  const message =
    error.response && error.response.data.message
      ? error.response.data.message
      : error.message

  if (!error.response || !error.response.status) {
    toast.add({
      severity: 'error',
      summary: title,
      detail: 'Gagal terhubung ke server. Harap cek koneksi internet Anda.',
      life: 3000,
    })

    return
  }

  switch (error.response.status) {
    case 400:
      toast.add({
        severity: 'error',
        summary: title,
        detail: message,
        life: 3000,
      })
      break
    case 422:
      if (error.response.data.data) {
        const data = error.response.data.data
        let msg = ''
        for (const [, value] of Object.entries(data)) {
          msg = msg + value
        }
        toast.add({
          severity: 'error',
          summary: title,
          detail: msg,
          life: 3000,
        })
      }
      break
    case 429:
      toast.add({
        severity: 'error',
        summary: title,
        detail: 'Too many request, please try again later.',
        life: 3000,
      })
      break
    default:
      toast.add({
        severity: 'error',
        summary: title,
        detail: message,
        life: 3000,
      })
      break
  }
}
