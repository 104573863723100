<template>
  <div>
    <Menubar :model="menuItems" class="fadeinup animation-duration-300">
      <template #start>
        <img alt="logo" src="/img/logo.png" height="40" class="mr-3" />
      </template>
      <template #end>
        <Button
          type="button"
          icon="pi pi-android"
          class="
            p-button-text p-button-rounded
            text-green-500
            bg-white-alpha-10
            mr-3
          "
          title="Unduh Aplikasi Android"
          :label="props.apkVersion"
          :loading="isLoadingDownload"
          @click="onDownloadAPK"
        />
        <Chip
          :label="notifyCount"
          icon="pi pi-bell"
          class="bg-primary mr-3"
          style="height: 35px"
          @click="toggleNotification"
        />
        <Button
          type="button"
          icon="pi pi-cog"
          class="p-button-text text-white bg-transparent mr-3"
          @click="$router.push('/master/setting')"
        />
        <Button
          type="button"
          :label="userName"
          icon="pi pi-user"
          class="p-button-text bg-transparent text-white"
          @click="onToggle"
        />
        <Menu ref="menu" :model="menuUser" :popup="true" />
      </template>
    </Menubar>
    <OverlayPanel
      ref="op"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '300px' }"
    >
      <ul class="p-0 mx-0 mt-0 list-none">
        <li class="flex align-items-center py-2">
          <template v-if="isMessageEmpty">
            <div
              class="
                flex
                align-items-center
                justify-content-center
                border-circle
                mr-3
                flex-shrink-0
              "
            >
              <i class="pi pi-info-circle text-blue-500"></i>
            </div>
            <span class="text-700 line-height-3">Tidak ada notifikasi.</span>
          </template>
          <template v-else>
            <ul style="width: 100%; list-style-type: none" class="p-0 m-0">
              <li
                :key="item"
                v-for="item in messages.slice().reverse()"
                class="pl-2 pr-2 m-1"
                :style="{
                  border: '1px solid #dddddd',
                  backgroundColor: item.reads === 0 ? '#eeeeee' : 'white',
                }"
                @click="readMessage(item)"
              >
                <p class="font-bold" style="line-height: 10px">
                  {{ item.title }}
                </p>
                <p>{{ item.body }}</p>
              </li>
            </ul>
          </template>
        </li>
      </ul>
      <Button
        label="Hapus notifikasi"
        class="w-full p-button-outlined p-button-secondary p-button-sm"
        :disabled="isMessageEmpty"
        @click="clearMessage"
      />
    </OverlayPanel>
    <Dialog
      v-model:visible="dialogLogout"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      modal
      closeable
    >
      <template #header>
        <div class="font-bold text-xl">
          <i class="pi pi-sign-out mr-2"></i> Logout
        </div>
      </template>
      Keluar dari aplikasi ?
      <template #footer>
        <Button
          label="Batal"
          icon="pi pi-times"
          class="p-button-text p-button-secondary"
          @click="dialogLogout = false"
        />
        <Button
          label="Logout"
          icon="pi pi-sign-out"
          autofocus
          @click="$router.push('/logout')"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue'
import Menubar from 'primevue/menubar'
import Chip from 'primevue/chip'
import errorHandler from '@/helpers/errorhandler'
import { useToast } from 'primevue/usetoast'
import { useAuthStore } from '@/store/auth'
import AppService from '@/services/AppService'

const props = defineProps({
  messages: {
    type: Array,
    default() {
      return []
    },
  },
  apkVersion:{
    type: String, 
    default(){
      return ""
    }
  }
})

const dialogLogout = ref(false)
const auth = useAuthStore()
const menuItems = ref([
  {
    label: 'Home',
    icon: 'pi pi-th-large',
    to: '/',
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'fakturis_pembelian', 'sp_penagihan', 'pembelian', 'gudang', 'pajak', 'apoteker', 'faktur_kembali'].includes(a)),
  },
  {
    label: 'Pembelian',
    icon: 'pi pi-clone',
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pembelian', 'pajak', 'sp_penagihan', 'fakturis_pembelian'].includes(a)),
    items: [
      {
        label: 'Rekomendasi Pembelian',
        to: '/pembelian/rekomendasi',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pembelian'].includes(a)),
      },
      {
        label: 'Purchase Order',
        to: '/pembelian/purchase-order',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pembelian', 'sp_penagihan', 'fakturis_pembelian'].includes(a)),
      },
      {
        label: 'Faktur Pembelian',
        to: '/pembelian/faktur',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pembelian', 'fakturis_pembelian'].includes(a)),
      },
      {
        label: 'Kontra Bon',
        to: '/pembelian/kontra-bon',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak'].includes(a)),
      },
      {
        separator: true,
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pembelian'].includes(a)),
      },
      {
        label: 'Retur Pembelian',
        to: '/pembelian/retur',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pembelian', 'fakturis_pembelian'].includes(a)),
      },
      {
        separator: true,
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak'].includes(a)),
      },
      {
        label: 'Pembayaran Hutang',
        to: '/pembelian/pembayaran',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak'].includes(a)),
      },
    ],
  },
  {
    label: 'Penjualan',
    icon: 'pi pi-shopping-cart',
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'gudang', 'sp_penagihan', 'pembelian', 'faktur_kembali'].includes(a)),
    items: [
      {
        label: 'Sales Order',
        to: '/penjualan/sales-order',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'gudang', 'sp_penagihan', 'pembelian'].includes(a)),
      },
      {
        label: 'Faktur Penjualan',
        to: '/penjualan/faktur',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'gudang', 'sp_penagihan', 'pembelian', 'faktur_kembali'].includes(a)),
      },
      {
        label: 'Kontra Bon',
        to: '/penjualan/kontra-bon',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'sp_penagihan'].includes(a)),
      },
      {
        separator: true,
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan'].includes(a)),
      },
      {
        label: 'Retur Penjualan',
        to: '/penjualan/retur',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan'].includes(a)),
      },
      {
        separator: true,
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp'].includes(a)),
      },
      {
        label: 'Pembayaran Piutang',
        to: '/penjualan/pembayaran',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp'].includes(a)),
      },
    ],
  },
  {
    label: 'Pajak',
    icon: 'pi pi-wallet',
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak'].includes(a)),
    items: [
      {
        label: 'Faktur Pajak Keluaran',
        to: '/pajak/faktur-pajak-keluaran',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak'].includes(a)),
      },
      {
        label: 'Faktur Pajak Masukan',
        to: '/pajak/faktur-pajak-masukan',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak'].includes(a)),
      },
      {
        label: 'No Seri Faktur Pajak',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak'].includes(a)),
        items: [
          {
            label: 'No Seri Pajak',
            to: '/pajak/seri',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp', 'pajak'].includes(a)
            ),
          },
          {
            label: 'Input Faktur Pajak',
            to: '/pajak/seri-input-faktur',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp', 'pajak'].includes(a)
            ),
          },
        ],
      },
      {
        label: 'Rekonsiliasi Faktur Penjualan',
        to: '/pajak/rekonsiliasi-penjualan',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak'].includes(a)),
      },
      {
        label: 'Rekonsiliasi Faktur Pembelian',
        to: '/pajak/rekonsiliasi-pembelian',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak'].includes(a)),
      },
    ],
  },
  {
    label: 'Gudang',
    icon: 'pi pi-inbox',
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pembelian', 'gudang', 'fakturis_pembelian'].includes(a)),
    items: [
      {
        label: 'Barang Masuk',
        to: '/gudang/barang-masuk',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'gudang', 'fakturis_pembelian'].includes(a)),
      },
      {
        label: 'Barang Keluar',
        to: '/gudang/barang-keluar',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'gudang'].includes(a)),
      },
      {
        separator: true,
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'gudang'].includes(a)),
      },
      {
        label: 'Stok Opname',
        to: '/gudang/stok-opname',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'gudang', 'pembelian'].includes(a)),
      },
      {
        label: 'Ganti Nomor Batch',
        to: '/gudang/ganti-batch',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'gudang'].includes(a)),
      },
    ],
  },
  {
    label: 'Keuangan',
    icon: 'pi pi-book',
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'sp_penagihan'].includes(a)),
    items: [
      {
        label: 'Perkiraan Akuntansi',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp'].includes(a)),
        items: [
          {
            label: 'Head Account',
            to: '/keuangan/head-account',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp'].includes(a)
            ),
          },
          {
            label: 'Kelompok Account',
            to: '/keuangan/kelompok-account',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp'].includes(a)
            ),
          },
          {
            label: 'Sub Kelompok Account',
            to: '/keuangan/sub-kelompok-account',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp'].includes(a)
            ),
          },
          {
            label: 'Perkiraan Akun',
            to: '/keuangan/perkiraan-akun',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp'].includes(a)
            ),
          },
        ],
      },
      {
        label: 'Daftar Kas Bank',
        to: '/master/daftar-kas',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp'].includes(a)),
      },
      {
        label: 'Transaksi',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'sp_penagihan'].includes(a)),
        items: [
          {
            label: 'Penerimaan Kas',
            to: '/keuangan/penerimaan',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp'].includes(a)
            ),
          },
          {
            label: 'Pengeluaran Kas',
            to: '/keuangan/pengeluaran',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp', 'sp_penagihan'].includes(a)
            ),
          },
          {
            label: 'Transfer Kas',
            to: '/keuangan/transfer',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp'].includes(a)
            ),
          },
          {
            label: 'Giro Masuk',
            to: '/keuangan/giro-masuk',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp'].includes(a)
            ),
          },
          {
            label: 'Giro Keluar',
            to: '/keuangan/giro-keluar',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp'].includes(a)
            ),
          },
          {
            label: 'Jurnal Umum',
            to: '/keuangan/jurnal-umum',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp'].includes(a)
            ),
          },
        ],
      },
      {
        label: 'Laporan',
        to: '/keuangan/laporan',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'sp_penagihan'].includes(a)),
      },
      {
        label: 'Setup Neraca Awal',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp'].includes(a)),
        items: [
          {
            label: 'Neraca Awal',
            to: '/keuangan/neraca-awal',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp'].includes(a)
            ),
          },
          {
            label: 'Saldo Kas',
            to: '/keuangan/saldo/kas',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp'].includes(a)
            ),
          },
          {
            label: 'Saldo Hutang',
            to: '/keuangan/saldo/hutang',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp'].includes(a)
            ),
          },
          {
            label: 'Saldo Piutang',
            to: '/keuangan/saldo/piutang',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp'].includes(a)
            ),
          },
          {
            label: 'Saldo Barang',
            to: '/keuangan/saldo/barang',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp'].includes(a)
            ),
          },
        ],
      },
    ],
  },
  {
    label: 'Master',
    icon: 'pi pi-folder',
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'pembelian', 'gudang', 'apoteker', 'sp_penagihan', 'fakturis_pembelian', 'faktur_kembali'].includes(a)),
    items: [
      {
        label: 'Area',
        to: '/master/area',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'apoteker'].includes(a)),
      },
      {
        label: 'Identitas Perusahaan',
        to: '/master/identitas-perusahaan',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'apoteker'].includes(a)),
      },
      {
        label: 'Komisi',
        to: '/master/komisi',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'apoteker'].includes(a)),
      },
      {
        label: 'Margin Global',
        to: '/master/margin-global',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'apoteker'].includes(a)),
      },
      {
        label: 'Obat',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'pembelian', 'gudang', 'apoteker', 'sp_penagihan', 'fakturis_pembelian'].includes(a)),
        items: [
          {
            label: 'Data Obat',
            to: '/master/obat',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp', 'fakturis_penjualan', 'pembelian', 'gudang', 'apoteker', 'sp_penagihan', 'fakturis_pembelian'].includes(a)
            ),
          },
          {
            label: 'Golongan Obat',
            to: '/master/golongan-obat',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp', 'fakturis_penjualan', 'pembelian', 'gudang', 'apoteker', 'sp_penagihan', 'fakturis_pembelian'].includes(a)
            ),
          },
          {
            label: 'Komposisi Obat',
            to: '/master/komposisi-obat',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp', 'fakturis_penjualan', 'pembelian', 'gudang', 'apoteker', 'sp_penagihan', 'fakturis_pembelian'].includes(a)
            ),
          },
          {
            label: 'Konversi Satuan',
            to: '/master/konversi-satuan',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp', 'fakturis_penjualan', 'pembelian', 'gudang', 'apoteker', 'sp_penagihan', 'fakturis_pembelian'].includes(a)
            ),
          },
        ],
      },
      {
        label: 'Pabrik',
        to: '/master/pabrik',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'apoteker', 'sp_penagihan', 'fakturis_pembelian'].includes(a)),
      },
      {
        label: 'Pelanggan',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'apoteker', 'faktur_kembali'].includes(a)),
        items: [
          {
            label: 'Data Pelanggan',
            to: '/master/pelanggan',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp', 'fakturis_penjualan', 'apoteker', 'faktur_kembali'].includes(a)
            ),
          },
          {
            label: 'Kelompok Pelanggan',
            to: '/master/kelompok-pelanggan',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp', 'fakturis_penjualan', 'apoteker', 'faktur_kembali'].includes(a)
            ),
          },
        ],
      },
      {
        label: 'Salesman',
        to: '/master/salesman',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'apoteker'].includes(a)),
      },
      {
        label: 'Supplier',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'apoteker', 'fakturis_pembelian'].includes(a)),
        items: [
          {
            label: 'Data Supplier',
            to: '/master/supplier',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp', 'apoteker', 'fakturis_pembelian'].includes(a)
            ),
          },
          {
            label: 'Kelompok Supplier',
            to: '/master/kelompok-supplier',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp', 'apoteker', 'fakturis_pembelian'].includes(a)
            ),
          },
        ],
      },
      {
        label: 'User & Akses',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp'].includes(a)),
        items: [
          {
            label: 'Role & Permission',
            to: '/master/role',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp'].includes(a)
            ),
          },
          {
            label: 'User',
            to: '/master/user',
            visible: auth.user.roles.some((a) =>
              ['admin', 'manager', 'edp'].includes(a)
            ),
          },
        ],
      },
    ],
  },
  {
    label: 'Laporan',
    icon: 'pi pi-file-o',
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'pembelian', 'gudang', 'pajak', 'apoteker', 'sp_penagihan', 'fakturis_pembelian'].includes(a)),
    items: [
      {
        label: 'Persediaan Barang',
        to: '/laporan/persediaan-barang',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'pembelian', 'gudang', 'apoteker', 'sp_penagihan', 'fakturis_pembelian'].includes(a)),
      },
      {
        label: 'Pembelian',
        to: '/laporan/pembelian',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pembelian', 'pajak', 'sp_penagihan', 'fakturis_pembelian'].includes(a)),
      },
      {
        label: 'Penjualan',
        to: '/laporan/penjualan',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'pajak', 'sp_penagihan'].includes(a)),
      },
      {
        label: 'Hutang Usaha',
        to: '/laporan/hutang-usaha',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak'].includes(a)),
      },
      {
        label: 'Piutang Usaha',
        to: '/laporan/piutang-usaha',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'sp_penagihan'].includes(a)),
      },
      {
        label: 'Salesman',
        to: '/laporan/salesman',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'sp_penagihan'].includes(a)),
      },
      {
        label: 'Apoteker',
        to: '/laporan/apoteker',
        visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'apoteker'].includes(a)),
      },
    ],
  },
])
const menuUser = ref([
  {
    label: 'Profile',
    icon: 'pi pi-user-edit',
    to: '/profile',
  },
  {
    separator: true,
  },
  {
    label: 'Logout',
    icon: 'pi pi-sign-out',
    command: () => {
      logout()
    },
  },
])
const menu = ref()
const op = ref()

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['reads', 'clear'])

const userName = computed(() => {
  return auth.user ? auth.user.name : ''
})

const notifyCount = computed(() => {
  let unread = 0
  if (props.messages) {
    props.messages.forEach((item) => {
      const reads = item.reads
      if (!reads) unread++
    })
  }
  return unread.toString()
})

const isMessageEmpty = computed(() => {
  return props.messages.length === 0
})

function onToggle(event) {
  menu.value.toggle(event)
}

function toggleNotification(event) {
  op.value.toggle(event)
}

function logout() {
  dialogLogout.value = true
}

function readMessage(item) {
  const reads = item.reads
  if (!reads) {
    item.reads = 1
    emit('reads', item)
  }
}

function clearMessage() {
  emit('clear')
}

const toast = useToast()
const appService = new AppService()
let isLoadingDownload = ref(false)

function onDownloadAPK() {
  isLoadingDownload.value = true
  const hash = Math.random().toString(36).slice(2)

  appService
    .downloadAPK(hash.substring(0, 6))
    .then((res) => {
      isLoadingDownload.value = false
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `farma-next-${hash.substring(0, 6)}.apk`)
      document.body.appendChild(link)
      link.click()
    })
    .catch((err) => {
      console.log(err)
      errorHandler(err, 'Download APK', toast)
    })
    .finally(() => (isLoadingDownload.value = false))
}
</script>

<style scoped>
.p-menubar {
  background: #2d3748;
  border: none;
  border-radius: 0;
}
</style>
