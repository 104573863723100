import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyAMbzGzTh8ZQG5LKdM1V8D-xKewvnVBRGk',
  authDomain: 'farmanex-test.firebaseapp.com',
  projectId: 'farmanex-test',
  storageBucket: 'farmanex-test.appspot.com',
  messagingSenderId: '17499702415',
  appId: '1:17499702415:web:ad7450a21db7292977dcfa',
}

const firebase = initializeApp(firebaseConfig)

export const messaging = getMessaging(firebase)
