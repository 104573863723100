import http from '../clients/Axios'
export default class AppService {
  async downloadAPK(hash) {
    return http({
      url: `/apk/download?${hash}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
