/* eslint-disable no-useless-escape */
export function isEmail(value) {
  const expression =
    // eslint-disable-next-line no-control-regex
    // eslint-disable-next-line no-useless-escape
    // eslint-disable-next-line no-control-regex
    // eslint-disable-next-line no-control-regex
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i
  return expression.test(String(value))
}

export function isNumber(value) {
  if (isNaN(parseInt(value))) {
    return false
  } else {
    return typeof parseInt(value) === 'number'
  }
}

export function formatCurrency(value, minDigit = 0, maxDigit = 0) {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: minDigit,
    maximumFractionDigits: maxDigit,
  }).format(value)
}

export function formatNumber(value) {
  return new Intl.NumberFormat('id-ID').format(value)
}

export function isObject(value, field) {
  if (Object.prototype.hasOwnProperty.call(value, field)) {
    return true
  }

  return false
}

export function formatDate(value, time = false) {
  let result = '-'

  if (value) {
    const currentTime = new Date(value)
    const month = currentTime.getMonth() + 1
    const day = currentTime.getDate()
    const year = currentTime.getFullYear()
    const hour =
      (currentTime.getHours() < 10 ? '0' : '') + currentTime.getHours()
    const minutes =
      (currentTime.getMinutes() < 10 ? '0' : '') + currentTime.getMinutes()
    const seconds =
      (currentTime.getSeconds() < 10 ? '0' : '') + currentTime.getSeconds()
    const date = day + '/' + month + '/' + year
    const times = hour + ':' + minutes + ':' + seconds
    result = date + (time ? ' ' + times : '')
  }

  return result
}
